import { postData } from "./rest";
import { eMessage } from "./e-message";

const qaItems = document.querySelectorAll('.qa__item');

qaItems.forEach((item) => {
    item.addEventListener('click', () => {
        item.classList.toggle('qa__item--active');
    });
});

const mobileMenuOpenBtn = document.querySelector('.e-header__menu-toggle');
const mobileMenuCloseBtn = document.querySelector('.mobile-menu__close');
const mobileMenu = document.querySelector('.mobile-menu');

mobileMenuOpenBtn.addEventListener('click', () => {
    mobileMenu.classList.add('mobile-menu--active');
});

mobileMenuCloseBtn.addEventListener('click', () => {
    mobileMenu.classList.remove('mobile-menu--active');
});

const headerNavLinks = document.querySelectorAll('.e-header__nav .e-link');
const hero = document.querySelector('.hero');

headerNavLinks.forEach((navLink) => {
    navLink.addEventListener('click', (e) => {
        e.preventDefault();
        let navLinkUrl = navLink.getAttribute('href');
        let navLinkHash = navLinkUrl.split('#')[1];

        // check if it's home page, & scroll to the block
        if (hero && navLinkHash) {
            document.querySelector('#' + navLinkHash).scrollIntoView({
                behavior: 'smooth'
            });
            mobileMenu.classList.remove('mobile-menu--active');
        } else {
            window.location.href = navLinkUrl;
        }
    });
});

const toggleInviteLink = document.querySelector('#toggleInviteLink');

toggleInviteLink?.addEventListener('click', (e) => {
    e.preventDefault();

    toggleInviteLink.classList.toggle('invite-page__toggle-links--active');

    document.querySelectorAll('.invite-url-td').forEach((el) => {
       el.classList.toggle('active');
    });
});




const inviteDeleteBtns = document.querySelectorAll('.inviteDelete');

inviteDeleteBtns?.forEach((btn) => {
    btn.addEventListener('click', (e) => {
        e.preventDefault();

        const url = btn.getAttribute('formaction');

        if (url) {
            postData(url)
                .then(res => {
                    if (res.ok) {
                        btn.parentNode.parentNode.remove();
                        eMessage('success', 'Запрошення видалено.');
                    } else {
                        eMessage('error', 'Щось пішло не так(');
                    }
                });
        }
    });
});

const inviteCopyUrlBtns = document.querySelectorAll('.copyInviteUrl');

inviteCopyUrlBtns?.forEach((btn) => {
    btn.addEventListener('click', (e) => {
        e.preventDefault();

        const url = btn.getAttribute('data-inviteurl');

        if (url) {
            navigator.clipboard.writeText(url)
            .then(() => {
                eMessage('success', 'Запрошення скопійовано в буфер обміну.');
            })
            .catch(err => {
                eMessage('error', 'Щось пішло не так(');
            });
        }
    });
});
