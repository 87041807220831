const eMessageContainer = document.querySelector('.e-messages');

export const eMessage = (type, text) => {

    const parentEl = document.createElement('div');
    parentEl.classList.add('e-message');
    parentEl.classList.add('e-message--' + type);
    parentEl.innerHTML = `<span>${text}</span>`

    const closeButton = document.createElement('button');
    closeButton.classList.add('e-message__close');
    closeButton.addEventListener('click', (e) => {
        e.preventDefault();

        parentEl.remove();
    });

    parentEl.appendChild(closeButton);

    eMessageContainer?.appendChild(parentEl);

    setTimeout(() => {
        parentEl.remove();
    }, 5000);
}